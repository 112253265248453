import React from 'react';
import {
  Finance,
  getRelatedProduct,
  useServicesContext,
  VehicleBasic,
  FinanceDisclaimer,
  RateLayouts,
  Buttons,
  trackClick,
} from '@oneaudi/vtp-shared';

import { Badge, Divider, LayoutItem, Text } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import Gallery from './Gallery/Gallery';
import { getContentConfiguration, isListView } from '../hooks/tileHook';
import DealerLabel from './DealerLabel';
import ActionButtons from './ActionButtons';
import {
  GreyText,
  GridContent,
  GridGallery,
  GridTileContainer,
  ListContent,
  ListGallery,
  ListTileContainer,
  StyledLink,
} from './Tile.styles';
import DetailInfo from './DetailInfo';
import Tabs, { hasConsumptionValues, hasWarrantyValues, hasCampaignsValues } from './Tabs/Tabs';
import {
  trackDetailsPageNav,
  trackLayerLinkClick,
  trackLayerOpen,
  trackLayerClose,
} from './tracking';
import { useVehicleDetailsPageUrl } from '../hooks/useVehicleDetailsPageUrl';
import { MatchingScore } from './MatchingScore';
import { ScoreAndFavoritesContainer } from './Tiles.styles';
import { CTAConfig, ConfigurationServiceV1 } from '@oneaudi/vtp-configuration-service';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';

interface TileProps {
  vehicle: VehicleBasic;
  matchingScore?: string;
}

const Tile: React.FC<TileProps> = ({ vehicle, matchingScore }: TileProps) => {
  const servicesContext = useServicesContext();
  const trackingService = servicesContext.getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const localeService = servicesContext.getAdditionalService(
    'gfa:locale-service'
  ) as LocaleServiceV1;
  const configurationService =
    useServicesContext().getAdditionalService<ConfigurationServiceV1 | null>(
      'vtp-configuration-service'
    );
  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as GfaLayerManagerV1;

  const contentConfiguration = getContentConfiguration();

  // Face Editor configuration to decide CTAs are btn/link.
  const useCTALinksToBtn = contentConfiguration?.tile.ctaStyle === 'buttons';

  const buyableOnlineLabel = useI18n({ id: 'nemo.ui.sc.tiles.buyable.online' });
  const leasingLabel = useI18n({ id: 'nemo.ui.sc.details.leasing.label' });
  const leasingLabelHighlighted = useI18n({ id: 'nemo.ui.sc.details.leasing.buyable-online' });
  const reservedLabel = useI18n({ id: 'nemo.ui.sc.tiles.reservedlabel' });
  const financeCheckoutTranslations = {
    popoverHeading: useI18n({
      defaultMessage: 'PopoverHeading',
      id: `nemo.ui.sc.fs.checkout.popover.heading`,
    }),
    popoverContent: useI18n({
      defaultMessage: 'PopoverContent',
      id: `nemo.ui.sc.fs.checkout.popover.content`,
    }),
    popoverCloseButton: useI18n({
      defaultMessage: 'PopoverClose',
      id: `nemo.ui.sc.fs.checkout.popover.close`,
    }),
    popoverContinueButton: useI18n({
      defaultMessage: 'PopoverContinue',
      id: `nemo.ui.sc.fs.checkout.popover.continue`,
    }),
  };

  const listView = isListView();
  const TileContainer = listView ? ListTileContainer : GridTileContainer;
  const GalleryContainer = listView ? ListGallery : GridGallery;
  const ContentContainer = listView ? ListContent : GridContent;

  const country = localeService.countryCode;

  const { getConfiguration, getEnvironmentConfig } = useServicesContext();
  const config = getConfiguration()!;
  const { scopes } = config;
  const envConfig = getEnvironmentConfig();

  // this needs to be resolved before potentially returning null in the case that vehicleId is not in vehicles
  const detailsPageLink = useVehicleDetailsPageUrl(vehicle) || '';

  if (!vehicle) {
    return null;
  }

  const isDEMarket = localeService.countryCode?.toLowerCase() === 'de';
  const hasConsumptionTab = () => {
    if (!isDEMarket) {
      return true;
    }
    return !!vehicle.envkv2024;
  };
  const hasTabs =
    (contentConfiguration?.tile.consumptionItems &&
      contentConfiguration?.tile.consumptionItems.length &&
      hasConsumptionValues(vehicle) &&
      hasConsumptionTab()) ||
    (contentConfiguration?.tile.warranties && hasWarrantyValues(vehicle)) ||
    hasCampaignsValues(vehicle);

  const loanTypeLink = {
    loanTypeLinkUrl: contentConfiguration?.tile.loanTypeLinkUrl || '',
    layerLinkUrl: contentConfiguration?.tile.loanTypeLayerUrl || '',
  };

  const relatedProduct = getRelatedProduct(vehicle);
  const trackFinanceInfoLayerCLick = () => {
    trackLayerLinkClick(
      trackingService,
      '',
      'icon',
      relatedProduct,
      contentConfiguration?.appContext
    );
    trackLayerOpen(
      trackingService,
      'vtp results - leasing layer',
      'vtp leasing layer',
      contentConfiguration?.appContext
    );
  };
  const trackFinanceInfoLayerClose = () => {
    trackLayerClose(trackingService, 'vtp leasing layer');
  };
  const trackButtonClick = (buttoninfo: CTAConfig, variant: string, trackingCode?: string) => {
    trackClick(
      trackingService,
      buttoninfo,
      variant,
      contentConfiguration?.appContext === 'suggested' ? 'vtp-suggested-cars' : 'vtp-results',
      vehicle.id,
      trackingCode
    );
  };

  const disableFinancing = !scopes?.financeEnabled || scopes.hideFinanceInTiles;

  const isUKMarket = country?.toLowerCase() === 'gb';

  return (
    <TileContainer data-testid="Tile">
      <GalleryContainer>
        <ScoreAndFavoritesContainer>
          {matchingScore && <MatchingScore matchingScore={matchingScore} vehicle={vehicle} />}
          <ActionButtons data-testid="ActionButton" vehicle={vehicle} />
        </ScoreAndFavoritesContainer>
        <Gallery vehicle={vehicle} detailsPageLink={detailsPageLink} isListView={listView} />
      </GalleryContainer>
      <ContentContainer>
        <StyledLink
          href={detailsPageLink}
          data-testid="carline"
          onClick={() =>
            trackDetailsPageNav(
              trackingService,
              `${vehicle.symbolicCarline?.description}`,
              detailsPageLink,
              'text link',
              contentConfiguration?.appContext
            )
          }
        >
          {vehicle.symbolicCarline?.description}
        </StyledLink>
        {vehicle.modelCode?.description && (
          <GreyText variant="copy2" spaceStackEnd="l" data-testid="modelCode">
            {vehicle.modelCode?.description}
          </GreyText>
        )}
        {vehicle.buyableOnline && (
          <Text variant="copy2" spaceStackEnd="s" data-testid="buyableOnline">
            <Badge variant="positive" spaceInlineEnd="xs" />
            {buyableOnlineLabel}
          </Text>
        )}
        {vehicle.leasingCar && (
          <Text variant="copy2" spaceStackEnd="s" data-testid="leasingCar">
            <Badge variant="positive" spaceInlineEnd="xs" />
            {leasingLabel} {leasingLabelHighlighted}
          </Text>
        )}
        {vehicle.reservation && (
          <Text variant="copy2" spaceStackEnd="s" data-testid="reservedCar">
            <Badge role="status" spaceInlineEnd="xs" />
            {reservedLabel}
          </Text>
        )}
        <Finance
          vehicle={vehicle}
          loanTypeLink={loanTypeLink}
          trackLayerLinkClick={trackFinanceInfoLayerCLick}
          trackFinInfoLayerClose={trackFinanceInfoLayerClose}
          configureFinanceComponents={{
            hideFinanceDisclaimer: disableFinancing || isUKMarket,
            disableFinancing,
            disableDynamicFinancing: true,
            hideActionPriceLabel: contentConfiguration?.tile.hideActionPriceLabel || false,
            compact: true,
          }}
        />
        <Divider as="hr" spaceStackEnd="m" data-testid="dealerLabelSeparator" />
        <DealerLabel vehicle={vehicle} />

        {!useCTALinksToBtn && !contentConfiguration?.tile.hideCTAs && (
          <LayoutItem spaceStackStart="s">
            <Buttons
              vehicle={vehicle}
              layerManager={layerManager}
              appContext="tiles"
              environmentConfig={envConfig}
              configurationService={configurationService}
              isTilesButtonMode={useCTALinksToBtn}
              trackButtonClick={trackButtonClick}
              financeCheckoutTranslations={financeCheckoutTranslations}
              showAsText
            />
          </LayoutItem>
        )}

        {contentConfiguration?.tile.detailInformation && (
          <Divider
            as="hr"
            spaceStackEnd="m"
            spaceStackStart={
              contentConfiguration?.tile.hideCTAs || useCTALinksToBtn ? 'm' : undefined
            }
            data-testid="detailInfoSeparator"
          />
        )}
        <DetailInfo vehicle={vehicle} />
        {hasTabs && (
          <>
            <Divider as="hr" spaceStackStart="s" data-testid="tabsSeparator" />
            <Tabs vehicle={vehicle} detailsPageLink={detailsPageLink} />
          </>
        )}

        {vehicle.financing && isUKMarket && !disableFinancing && (
          <>
            <Divider
              as="hr"
              spaceStackStart="s"
              spaceStackEnd="s"
              data-testid="financialDisclaimerSeparator"
            />
            <FinanceDisclaimer
              vehicleId={vehicle.id}
              financing={vehicle.financing}
              layout={RateLayouts.DEFAULT_LAYOUT}
              trackLayerLinkClick={trackFinanceInfoLayerCLick}
              trackFinInfoLayerClose={trackFinanceInfoLayerClose}
              hideInfoIcon
            />
          </>
        )}

        {!contentConfiguration?.tile.hideCTAs && (
          <Buttons
            vehicle={vehicle}
            layerManager={layerManager}
            trackButtonClick={trackButtonClick}
            appContext="tiles"
            environmentConfig={envConfig}
            configurationService={configurationService}
            isTilesButtonMode={useCTALinksToBtn}
            financeCheckoutTranslations={financeCheckoutTranslations}
            isTilesListView={listView}
          />
        )}

        <Divider as="hr" spaceStackStart="s" spaceStackEnd="s" data-testid="ctaSeparator" />
      </ContentContainer>
    </TileContainer>
  );
};

export default Tile;
