import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import App from './FeatureApp';

import I18nContextComponent from '@oneaudi/i18n-context';
import { AudiPlatformProvider } from '@audi/audi-ui-react-v2';
import { Provider } from 'react-redux';
import {
  AudiStockcarsStoreServiceV1,
  defineAudiStockcarsStoreService,
} from '@oneaudi/stockcars-store-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiFootnoteServiceInterfaceV1 } from '@volkswagen-onehub/audi-footnote-service';
import { Store } from 'redux';
import { ContentService } from '@volkswagen-onehub/audi-content-service';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import {
  CtaEditorConfig,
  DealerIdFilterType,
  FootnoteReferenceContextProvider,
  FootnoteServiceContextProvider,
  initializeFeatureApp,
  ServicesContextProvider,
} from '@oneaudi/vtp-shared';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { NotificationTypeV1 } from '@volkswagen-onehub/audi-notification-display-service';
import { defineConfigurationService } from '@oneaudi/vtp-configuration-service';
import { OneGraphQueryServiceV1 } from '@oneaudi/onegraph-query-service';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service';
import {
  TileContentConfiguration,
  ContentFields,
  SuggestedCars,
  MatchMaker,
  PreviouslyViewedCars,
  Tile,
} from '../@types/editor.d';

interface Dependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:server-request': ServerRequestV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 'audi-stockcars-store-service': AudiStockcarsStoreServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-footnote-service': AudiFootnoteServiceInterfaceV1;
  readonly 'gfa:layer-manager': GfaLayerManagerV1;
  readonly 'gfa:locale-service': LocaleServiceV1;
  readonly 'audi-content-service': ContentService;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'notification-display-service'?: NotificationTypeV1;
  readonly 'onegraph-query-service': OneGraphQueryServiceV1;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'audi:envConfigService': EnvConfigServiceV1;
}

export interface FeatureAppConfig {
  contextServiceUrl?: string;
  contextId?: string;
  baseUrl?: string;
  warrantyPlusLogoUrl?: string;
  vehicleId?: string;
}

export type CombinedConfig = FeatureAppConfig & TileContentConfiguration;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      's2:serialized-state-manager': '^1.0.0',
      'audi-stockcars-store-service': '1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'gfa:locale-service': '1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-content-service': '^1.0.0',
      'onegraph-query-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
      'vtp-configuration-service': '^1.0.0',
      'audi:envConfigService': '1.0.0',
    },
    externals: {
      react: '^17.0.2 || ^18.2.0',
      'styled-components': '*',
      '@audi/audi-ui-react-v2': '*',
      '@oneaudi/onegraph-query-service': '*',
      '@oneaudi/onegraph-service': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:server-request': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-footnote-service': '1.0.0',
      'notification-display-service': '^1.0.0',
      'gfa:layer-manager': '1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [defineAudiStockcarsStoreService(), defineConfigurationService()],

  create: ({ featureServices, config }: FeatureAppEnvironment<Dependencies, FeatureAppConfig>) => {
    const stockcarsStoreService: AudiStockcarsStoreServiceV1 =
      featureServices['audi-stockcars-store-service'];

    // do not use destructuring here, as this breaks the ssr build
    const initialization = initializeFeatureApp<Dependencies, FeatureAppConfig>(
      featureServices,
      config!
    );

    const audiContentService = featureServices['audi-content-service'] as ContentService;
    // eslint-disable-next-line no-underscore-dangle
    const combinedConfig: CombinedConfig = { ...config, ...mapContent(audiContentService!) };

    return {
      loadingPromise: initialization.loadingPromise?.featureAppInitializationPromise,
      render: () => (
        <AudiPlatformProvider>
          <Provider store={stockcarsStoreService.store.store as unknown as Store}>
            <ServicesContextProvider
              audiStockcarsStoreService={stockcarsStoreService}
              contextId={initialization.contextId!}
              featureAppConfig={combinedConfig}
              additionalServices={featureServices}
              envConfig={initialization.envConfig}
            >
              <I18nContextComponent
                i18nData={featureServices['dbad:audi-i18n-service']}
                language={featureServices['gfa:locale-service'].language}
                // @ts-ignore
                featureServices={featureServices}
                scopes={[]}
              >
                <FootnoteServiceContextProvider
                  footnoteService={featureServices['audi-footnote-service']}
                >
                  <FootnoteReferenceContextProvider
                    footnoteReferenceServiceScopeManager={
                      featureServices['audi-footnote-reference-service']
                    }
                  >
                    {initialization.loadingPromise?.state.initialized && <App />}
                  </FootnoteReferenceContextProvider>
                </FootnoteServiceContextProvider>
              </I18nContextComponent>
            </ServicesContextProvider>
          </Provider>
        </AudiPlatformProvider>
      ),
    };
  },
};

type KeyValue<Type> = { [key: string]: Type };

export function mapContent(contentService: ContentService): TileContentConfiguration {
  const content = contentService.getContent();

  // eslint-disable-next-line no-underscore-dangle
  if (content?.__type === 'aem-headless') {
    const contentFields: ContentFields = content.fields;
    const config = {} as Partial<TileContentConfiguration>;
    Object.keys(contentFields).forEach((fieldKey) => {
      if (fieldKey.startsWith('urls_')) {
        config.urls = {} as any;
        config.urls!.newSearchUrl = contentFields.urls_newSearchUrl;
        config.urls!.detailsPageUrlPattern = contentFields.urls_detailsPageUrlPattern;
      } else if (fieldKey.startsWith('sortParams_')) {
        config.sortParams = {} as any;
        config.sortParams!.options = contentFields.sortParams_options;
        config.sortParams!.defaultOption = contentFields.sortParams_defaultOption;
      } else if (fieldKey.startsWith('overWriteSorting_')) {
        config.overWriteSorting = {} as any;
        config.overWriteSorting!.filterToTriggerSorting =
          contentFields.overWriteSorting_filterToTriggerSorting;
        config.overWriteSorting!.overrideDefaultSortingKey =
          contentFields.overWriteSorting_overrideDefaultSortingKey;
      } else if (fieldKey.startsWith('suggestedCars_')) {
        config.suggestedCars = {
          ...config.suggestedCars,
          similarCars: {
            ...config.suggestedCars?.similarCars,
            matchmaker: config.suggestedCars?.similarCars?.matchmaker ?? ({} as MatchMaker),
          },
          similarCarsFromDealer: {
            ...config.suggestedCars?.similarCarsFromDealer,
            matchmaker:
              config.suggestedCars?.similarCarsFromDealer?.matchmaker ?? ({} as MatchMaker),
          },
          previouslyViewedCars:
            config.suggestedCars?.previouslyViewedCars ?? ({} as PreviouslyViewedCars),
        } as SuggestedCars;
        switch (fieldKey) {
          case 'suggestedCars_tabsEnabled':
            config.suggestedCars!.tabsEnabled = contentFields.suggestedCars_tabsEnabled;
            break;
          case 'suggestedCars_tabInitial':
            config.suggestedCars!.tabInitial = contentFields.suggestedCars_tabInitial;
            break;
          case 'suggestedCars_similarCars_numberOfCars':
            config.suggestedCars!.similarCars.numberOfCars =
              contentFields.suggestedCars_similarCars_numberOfCars;
            break;
          case 'suggestedCars_similarCars_matchmaker_parameter':
            config.suggestedCars!.similarCars.matchmaker!.parameter =
              contentFields.suggestedCars_similarCars_matchmaker_parameter;
            break;
          case 'suggestedCars_similarCars_matchmaker_score':
            config.suggestedCars!.similarCars.matchmaker!.score =
              contentFields.suggestedCars_similarCars_matchmaker_score;
            break;
          case 'suggestedCars_similarCars_matchmaker_displayScore':
            config.suggestedCars!.similarCars.matchmaker!.displayScore =
              contentFields.suggestedCars_similarCars_matchmaker_displayScore;
            break;
          case 'suggestedCars_similarCarsFromDealer_numberOfCars':
            config.suggestedCars!.similarCarsFromDealer.numberOfCars =
              contentFields.suggestedCars_similarCarsFromDealer_numberOfCars;
            break;
          case 'suggestedCars_similarCarsFromDealer_matchmaker_parameter':
            config.suggestedCars!.similarCarsFromDealer.matchmaker!.parameter =
              contentFields.suggestedCars_similarCarsFromDealer_matchmaker_parameter;
            break;

          case 'suggestedCars_similarCarsFromDealer_matchmaker_score':
            config.suggestedCars!.similarCarsFromDealer.matchmaker!.score =
              contentFields.suggestedCars_similarCarsFromDealer_matchmaker_score;
            break;
          case 'suggestedCars_similarCarsFromDealer_matchmaker_displayScore':
            config.suggestedCars!.similarCarsFromDealer.matchmaker!.displayScore =
              contentFields.suggestedCars_similarCarsFromDealer_matchmaker_displayScore;
            break;
          case 'suggestedCars_previouslyViewedCars_numberOfCars':
            config.suggestedCars!.previouslyViewedCars.numberOfCars =
              contentFields.suggestedCars_previouslyViewedCars_numberOfCars;
            break;
          default:
            break;
        }
      } else if (fieldKey.startsWith('tile_') && fieldKey !== 'tile_cta') {
        config.tile = config.tile ?? ({} as Tile);
        switch (fieldKey) {
          case 'tile_consumptionItems':
            config.tile!.consumptionItems = contentFields.tile_consumptionItems;
            break;
          case 'tile_detailInformation':
            config.tile!.detailInformation = contentFields.tile_detailInformation;
            break;
          case 'tile_warranties':
            config.tile!.warranties = contentFields.tile_warranties;
            break;
          case 'tile_distinctiveDGT_showDistinctiveDGTTab':
          case 'tile_distinctiveDGT_pollutionBadgeBaseURL':
            config.tile!.distinctiveDGT = {
              showDistinctiveDGTTab: contentFields.tile_distinctiveDGT_showDistinctiveDGTTab,
              pollutionBadgeBaseURL: contentFields.tile_distinctiveDGT_pollutionBadgeBaseURL,
            };
            break;
          case 'tile_warrantyInfoUrl':
            config.tile!.warrantyInfoUrl = contentFields.tile_warrantyInfoUrl;
            break;
          case 'tile_loanTypeLinkUrl':
            config.tile!.loanTypeLayerUrl = contentFields.tile_loanTypeLinkUrl;
            break;
          case 'tile_loanTypeLayerUrl':
            config.tile!.loanTypeLayerUrl = contentFields.tile_loanTypeLayerUrl;
            break;
          case 'tile_hideDealerZipCode':
            config.tile!.hideDealerZipCode = contentFields.tile_hideDealerZipCode;
            break;
          case 'tile_hideActionPriceLabel':
            config.tile!.hideActionPriceLabel = contentFields.tile_hideActionPriceLabel;
            break;
          case 'tile_hideCTAs':
            config.tile!.hideCTAs = contentFields.tile_hideCTAs;
            break;
          case 'tile_ctaStyle':
            config.tile!.ctaStyle = contentFields.tile_ctaStyle;
            break;
          case 'tile_featureApps_consumptionVersion':
          case 'tile_featureApps_warrantiesVersion':
            config.tile!.featureApps = {
              consumptionVersion: contentFields.tile_featureApps_consumptionVersion,
              warrantiesVersion: contentFields.tile_featureApps_warrantiesVersion,
            };
            break;
          default:
            break;
        }
      } else if (fieldKey.startsWith('tile_cta')) {
        config.tile!.cta = [];
        contentFields.tile_cta?.forEach((cta: any) => {
          const ctaEntry = {} as Partial<CtaEditorConfig>;
          const ctaFields = cta.fields;
          Object.keys(ctaFields).forEach((ctaFieldKey) => {
            if (ctaFieldKey.startsWith('options_')) {
              ctaEntry.options = {};
              ctaEntry.options.apiKey = ctaFields.options_apiKey;
              ctaEntry.options.replacePhone = ctaFields.options_replacePhone;
              ctaEntry.options.filterByDealerId = {} as any;
              ctaEntry.options.filterByDealerId!.filterList =
                ctaFields.options_filterByDealerId_filterList;
              ctaEntry.options.filterByDealerId!.filterType =
                ctaFields.options_filterByDealerId_filterType as DealerIdFilterType;
            } else {
              (ctaEntry as KeyValue<any>)[ctaFieldKey] = (ctaFields as KeyValue<any>)[ctaFieldKey];
            }
          });
          config.tile!.cta!.push(ctaEntry as CtaEditorConfig);
        });
      } else {
        (config as KeyValue<any>)[fieldKey] = (contentFields as KeyValue<any>)[fieldKey];
      }
    });
    return config as TileContentConfiguration;
  }
  return content as TileContentConfiguration;
}

export default featureAppDefinition;
