import React, { useEffect } from 'react';
import { Button, PopoverBody, PopoverTrigger, Select, Text } from '@audi/audi-ui-react-v2';
import { useDispatch, useSelector } from 'react-redux';
import { ACTIONS, FilterDTO, SELECTORS } from '@oneaudi/stck-store';
import {
  useServicesContext,
  generateFilterRequestParam,
  useSvd,
  fetchResults,
  Icon,
  useScsUrlParts,
  useShareUrl,
  DangerousElement,
  iconSystemInfoSmall,
  iconShareSmall,
  useFilterPresetUrl,
} from '@oneaudi/vtp-shared';
import {
  CountAndSortContainer,
  CountAndShareContainer,
  SortContainer,
  StyledInfoText,
} from './ResultsBar.styles';
import { useI18n } from '@oneaudi/i18n-context';
import { trackSorting } from './tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { NotificationDisplayServiceV1 } from '@volkswagen-onehub/audi-notification-display-service';
import { TileContext } from '../hooks/tileHook';
import { ConfigurationServiceV1, SortOption } from '@oneaudi/vtp-configuration-service';
import { CombinedConfig } from '../FeatureHubAppDefinition';

interface ResultsBarProps {
  count: string;
  label: string;
}

const ResultsBar: React.FC<ResultsBarProps> = ({ count, label }) => {
  const getTranslatedOption = (value: string) => {
    // Some markets wish to maintain all text keys for the sorting options separately [CSR-1048]
    // So we first try to get a direct mapping from `value` of the i18n
    // if a direct mapping is available we use this otherwise we proceed with the existing logic
    const directTranslatedOption = useI18n({
      id: `nemo.sc.sort.${value?.replace(':', '.')}`,
      defaultMessage: '',
    });
    if (directTranslatedOption) return directTranslatedOption;

    const splittedVal = value.split(':');
    const sortParam = useI18n({ id: `nemo.sc.sort.${splittedVal[0]}` });

    const ascOrDesc =
      splittedVal.length > 1 && splittedVal[1] !== 'single'
        ? useI18n({ id: `nemo.sc.sort.${splittedVal[1]}` })
        : '';
    return `${sortParam} ${ascOrDesc}`;
  };

  const configuration = useServicesContext().featureAppConfig as CombinedConfig;
  const configurationFA = useServicesContext().getAdditionalService(
    'vtp-configuration-service'
  ) as ConfigurationServiceV1;
  const sortingConfiguration = configurationFA.getConfiguration()?.sortParams
    ? configurationFA?.getConfiguration()
    : configuration;

  // Configured in Content Editor
  // name of the filter that should enforce a resorting
  const filterToTriggerResorting =
    sortingConfiguration?.overWriteSorting?.filterToTriggerSorting || '';
  // name of the key in which resorting should happen
  const overrideDefaultSortingKey =
    sortingConfiguration?.overWriteSorting?.overrideDefaultSortingKey || '';
  const notificationDisplayService = useServicesContext().getAdditionalService(
    'notification-display-service'
  ) as NotificationDisplayServiceV1;

  const sortLabel1 = useI18n({ id: 'nemo.ui.sc.possible-sort-attributes', defaultMessage: 'Sort' });
  const sortLabel2 = useI18n({ id: 'nemo.ui.sc.possible-sort-attributes-after' });
  const [sortValue, setSortValue] = React.useState(useI18n({ id: 'nemo.sc.sort.relevance' }));
  const shareURLWithFilters = useI18n({
    id: 'nemo.ui.sc.share.filters.url',
    defaultMessage: 'Share',
  });
  const copySuccessfulNotificationMsg = useI18n({
    id: 'nemo.ui.sc.link.copied.success',
    defaultMessage: 'Link copied.',
  });
  const scsUrlParts = useScsUrlParts();
  const isUsedCarMkt = scsUrlParts?.marketPath?.includes('uc');
  // This following tooltip text needs to be shown (only) for DE market as a backup if textkey value is not maintained or is " " in AEM
  const sortingInfo =
    'Die Ergebnisse Ihrer Suchanfrage werden nach dem Parameter "Preis ansteigend" gelistet und präsentiert. Sie können über die "Filterfunktion/Sortieren nach"-Funktion eigene Kriterien für Ihre Suchanfrage bestimmen.';
  const sortExplanationNC: string = useI18n({
    id: 'nemo.ui.sc.nc.result.car.sorting.explanation',
    defaultMessage: '',
  });
  const sortExplanationUC: string = useI18n({
    id: 'nemo.ui.sc.uc.result.car.sorting.explanation',
    defaultMessage: '',
  });

  const favoriteVehicleIDs = useSelector((state: unknown) =>
    SELECTORS.VEHICLES.getFavoriteVehicleIdsState(state)
  ) as string[];

  const hasCampaignResults = useSelector((state) => {
    if (!('getFilterGroups' in SELECTORS.FILTERS)) {
      return {};
    }
    const filterGroups = SELECTORS.FILTERS.getFilterGroups(state);
    const campaigns = Object.values(filterGroups?.campaigns?.members || {});
    const campaignsWithinResults = campaigns.filter((campaign: any) => campaign.count > 0);
    return campaignsWithinResults.length > 0;
  });

  const tileContext = React.useContext(TileContext);

  /* eslint-disable no-nested-ternary */
  const sortExplanationNCLabel =
    sortExplanationNC.trim() !== ''
      ? sortExplanationNC
      : scsUrlParts?.market === 'de'
        ? sortingInfo
        : '';
  const sortExplanationUCLabel =
    sortExplanationUC.trim() !== ''
      ? sortExplanationUC
      : scsUrlParts?.market === 'de'
        ? sortingInfo
        : '';
  /* eslint-enable no-nested-ternary */
  const sortParams =
    (sortingConfiguration?.sortParams?.options?.length ?? 0) > 0
      ? sortingConfiguration?.sortParams?.options!.filter(
          (param: SortOption) => param !== null && (param as string) !== ''
        )
      : [];
  const dispatch = useDispatch();
  const svd = useSvd();
  const { filters, presets, isLocationSet } = useSelector((state: any) => {
    const storedSortValue = SELECTORS.SORTING.getSortingStateRaw(state);
    if (storedSortValue?.results !== 'relevance:asc' && storedSortValue?.results !== sortValue) {
      setSortValue(storedSortValue.results);
    }

    return {
      filters: SELECTORS.FILTERS.getSelectedFiltersMap(state) as Map<string, FilterDTO>,
      presets: SELECTORS.FILTERS.getPersistedPresetsMap(state),
      isLocationSet: SELECTORS.UI.getShowDistance(state),
    };
  });

  const hideSelect = tileContext.appContext === 'favorites' && Number(count) <= 1;
  const { getEnvironmentConfig, getConfiguration } = useServicesContext();
  const fetchFilteredResults = async (requestParam: any) => {
    if (tileContext.appContext === 'favorites') {
      fetchResults(
        getEnvironmentConfig()!.scs?.baseUrl,
        getConfiguration()!.scs?.scsMarketPath!,
        requestParam
      ).then((response: any) => {
        const vehicleIds = response?.vehicleBasic?.map((vehicle: { id: any }) => vehicle.id) || [];
        dispatch(ACTIONS.FAVORITE_VEHICLES.setFavoriteVehicleIds({ vehicleIds }));
      });
    } else {
      fetchResults(
        getEnvironmentConfig()!.scs.baseUrl,
        getConfiguration()!.scs!.scsMarketPath!,
        requestParam
      ).then((response: any) => {
        dispatch(ACTIONS.FILTERS.handleFilterResponse({ response }));
      });
    }
  };

  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;

  const setSortingAndFetchResults = (sortingLabel: string, sortingKey: string): void => {
    setSortValue(sortingLabel);
    dispatch(ACTIONS.SORTING.setSorting({ sorting: { results: sortingKey } }));
    const requestParam = generateFilterRequestParam(filters, presets);
    requestParam.set('size', tileContext.appContext === 'favorites' ? count : 12);
    requestParam.set('sort', sortingKey);
    requestParam.set('svd', svd.svd);
    if (tileContext.appContext === 'favorites') {
      requestParam.set(
        'filter',
        favoriteVehicleIDs?.map((vehId) => 'vehicle.'.concat(vehId))
      );
    }
    fetchFilteredResults(requestParam);
  };

  const inputRef = React.useRef<HTMLSelectElement>(document.createElement('select'));
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const currentOptionIndex = inputRef.current.options.selectedIndex;
    const currentSortLabel = inputRef.current.options[currentOptionIndex].label;
    const currentSortParam = inputRef.current.options[currentOptionIndex].value;
    setSortingAndFetchResults(currentSortLabel, currentSortParam);
    trackSorting(trackingService, currentSortLabel, currentSortParam);
  };

  const defaultOption = sortingConfiguration?.sortParams?.defaultOption;
  const allSortParams = defaultOption && sortParams ? [defaultOption!, ...sortParams] : sortParams;
  const allParamsWithoutDuplicates = [...new Set(allSortParams)];
  const getSortParamsExclDist = (): string[] =>
    allParamsWithoutDuplicates.filter((param: string) => !param.includes('byDistance'));
  let sortParamsToShow = isLocationSet ? allParamsWithoutDuplicates : getSortParamsExclDist();
  sortParamsToShow = hasCampaignResults
    ? sortParamsToShow
    : sortParamsToShow.filter((param) => param !== 'actionVehicles');

  const [currentFilterWhichTriggersResorting, setcurrentFilterWhichTriggersResorting] =
    React.useState<FilterDTO | null>(null);

  useEffect(() => {
    const distanceSorting = 'byDistance:asc';
    if (isLocationSet) {
      dispatch(
        ACTIONS.SORTING.setSorting({
          sorting: { results: distanceSorting, favorites: distanceSorting },
        })
      );
    } else if (!isLocationSet && !filters.get(filterToTriggerResorting)) {
      dispatch(
        ACTIONS.SORTING.setSorting({
          sorting: { results: defaultOption, favorites: defaultOption },
        })
      );
    } else if (filters.get(filterToTriggerResorting) && sortValue !== overrideDefaultSortingKey) {
      const filterWhichTriggersResorting = filters.get(filterToTriggerResorting)!;
      if (
        currentFilterWhichTriggersResorting &&
        filterWhichTriggersResorting.values[0] === currentFilterWhichTriggersResorting?.values[0] &&
        filterWhichTriggersResorting.values[1] === currentFilterWhichTriggersResorting.values[1]
      ) {
        return;
      }
      dispatch(ACTIONS.SORTING.setSorting({ sorting: { results: overrideDefaultSortingKey } }));
      setcurrentFilterWhichTriggersResorting(filters.get(filterToTriggerResorting)!);
    }
  }, []);

  const getPopoverBody = (sortExplanation: any): JSX.Element => (
    <PopoverTrigger tip placement="bottom right">
      {(triggerProps: any) => (
        <>
          <Button
            spaceInlineStart="l"
            spaceStackStart="m"
            variant="text"
            {...triggerProps}
            data-testid="sortingTooltip"
            icon={<Icon icon={iconSystemInfoSmall} />}
          />
          <StyledInfoText aria-label="sorting info">
            <PopoverBody>
              <Text>
                <DangerousElement sanitize html={sortExplanation} />
              </Text>
            </PopoverBody>
          </StyledInfoText>
        </>
      )}
    </PopoverTrigger>
  );

  const copyURLToShare = useShareUrl(notificationDisplayService, copySuccessfulNotificationMsg);

  const combinedString = useFilterPresetUrl();

  const shareUrl =
    typeof window !== 'undefined' ? `${window.location.href}${combinedString || ''}` : '';

  return (
    <CountAndSortContainer data-testid="countSortContainer">
      <CountAndShareContainer>
        <Text as="h2" variant="order3" spaceStackEnd="l" data-testid="vehicleCount">
          {`${count} ${label}`}
        </Text>
        <Button
          variant="text"
          onClick={() => copyURLToShare(shareUrl)}
          spaceStackEnd="l"
          spaceInlineStart="l"
          data-testid="shareLink"
          icon={<Icon icon={iconShareSmall} />}
        >
          {shareURLWithFilters}
        </Button>
      </CountAndShareContainer>
      {sortParamsToShow && sortParamsToShow.length && !hideSelect ? (
        <SortContainer>
          <Select
            inputId="sorting"
            label={`${sortLabel1} ${sortLabel2}`.trim()}
            value={sortValue}
            required
            inputRef={inputRef}
            onChange={handleChange}
            data-testid="sort-select"
          >
            {sortParamsToShow.map((sortParam: string) => (
              <option key={sortParam} value={sortParam}>
                {getTranslatedOption(sortParam)}
              </option>
            ))}
          </Select>
          {!isUsedCarMkt && sortExplanationNCLabel && getPopoverBody(sortExplanationNCLabel)}
          {isUsedCarMkt && sortExplanationUCLabel && getPopoverBody(sortExplanationUCLabel)}
        </SortContainer>
      ) : (
        <></>
      )}
    </CountAndSortContainer>
  );
};

export default ResultsBar;
