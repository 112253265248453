import React from 'react';
import { Text, Layout, LayoutItem, Button, ButtonProps } from '@audi/audi-ui-react-v2';
import {
  DealerChainInfoButton,
  FootnoteReference,
  getRelatedProduct,
  Icon,
  isBevAgencyVehicle,
  isNationWideSellingVehicle,
  useBevAgencyLabels,
  useDealerLabels,
  useNationWideSellingLabels,
  useServicesContext,
  VehicleBasic,
  DealerLinkObject,
  iconPoiSmall,
} from '@oneaudi/vtp-shared';
import { useI18n } from '@oneaudi/i18n-context';
import styled from 'styled-components';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import { LayerContentHTML } from '@oneaudi/fa-one-layer';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { trackLayerLinkClick, trackLayerOpen, trackLayerClose } from './tracking';
import { TrackingServiceV2 } from '@volkswagen-onehub/audi-tracking-service';
import { getContentConfiguration } from '../hooks/tileHook';
import { InfoLayerConfig } from '@oneaudi/vtp-configuration-service';

export interface DealerProps {
  vehicle: VehicleBasic;
}

function handleAEMLayerLink(url: string | undefined) {
  if (!url) {
    return undefined;
  }
  const absoluteUrlPattern = /^https?:\/\//i;
  if (absoluteUrlPattern.test(url)) {
    return url;
  }
  return url.replace(/\.html$/, '.headless.html');
}

const DealerLabel: React.FC<DealerProps> = ({ vehicle }) => {
  const { getConfiguration } = useServicesContext();
  const contentConfiguration = getContentConfiguration();

  const { nwsLabel, nwsSubLabel, nwsHeadline } = useNationWideSellingLabels(vehicle);
  const { dealerLabel, dealerSubLabel, dealerHeadline, dealerName } = useDealerLabels(vehicle);
  const { bevAgencyLabel, bevAgencySubLabel, bevAgencyHeadline, bevAgencyDealerName } =
    useBevAgencyLabels(vehicle);
  const isvLabel = useI18n({ id: 'nemo.ui.sc.investor-shared-vehicle.label', defaultMessage: '' });

  const employeeLabel = useI18n({
    id: 'nemo.ui.sc.messages.dealer.employeeLabel',
    defaultMessage: '',
  });
  const unitTextLabel = useI18n({
    id: 'nemo.ui.sc.range-unit.label',
    defaultMessage: '',
  });
  const trackingService = useServicesContext().getAdditionalService(
    'audi-tracking-service'
  ) as TrackingServiceV2;
  const layerManager = useServicesContext().getAdditionalService(
    'gfa:layer-manager'
  ) as GfaLayerManagerV1;

  const openLayer = (url: string, label: string) => {
    window.location.hash = `vehicleid=${encodeURIComponent(vehicle.id)}`;
    layerManager?.openFocusLayer(
      () => (
        <LayerContainer>
          <LayerContentHTML url={url} data-testid="layerContent" />
        </LayerContainer>
      ),
      {},
      {
        size: FocusLayerSizeV2.A,
        userCloseable: true,
        onClose: () => {
          window.location.hash = '';
          if (trackingService) {
            trackLayerClose(trackingService, 'vtp dealer info layer');
          }
        },
      }
    );
    const relatedProduct = getRelatedProduct(vehicle);
    trackLayerLinkClick(trackingService, label, 'text link', relatedProduct);
    trackLayerOpen(trackingService, 'vtp results - dealer info layer', 'vtp dealer info layer');
  };

  type LinkType = 'isv' | 'nws';
  const getInfoLayerLink = (type: LinkType) =>
    getConfiguration()!.infoLayerLinks?.find((link: InfoLayerConfig) => link.type === type);
  const getDealerContact = () => {
    if (vehicle.investorSharedVehicle) {
      const originalGroupName = vehicle?.dealer?.dealerContextLinkData?.filter(
        (ctx: DealerLinkObject) => 'originalGroupName' in ctx
      )[0]?.originalGroupName;
      return (
        <DealerChainInfoButton
          label=""
          vehicleId={vehicle.id}
          onClick={() => {
            const relatedProduct = getRelatedProduct(vehicle);
            trackLayerLinkClick(trackingService, isvLabel, 'text link', relatedProduct);
            trackLayerOpen(
              trackingService,
              'vtp results - dealer info layer',
              'vtp dealer info layer'
            );
          }}
        >
          <Text variant="copy2" as="span">
            {originalGroupName
              ? isvLabel.replace('{{originalGroupName}}', originalGroupName)
              : isvLabel.replace('{{originalGroupName}}', '')}
          </Text>
        </DealerChainInfoButton>
      );
    }
    if (isNationWideSellingVehicle(vehicle)) {
      const nwsDealerInfoLink = getInfoLayerLink('nws');
      const nwsLink = nwsDealerInfoLink?.url;
      const hasNwsLink = nwsLink && nwsLink !== '';

      return (
        <>
          {nwsHeadline.trim() !== '' && (
            <Text variant="copy2" as="span" data-testid="nws-headline">
              {nwsHeadline}
              <FootnoteReference refId="fn_nws_offer_stockcar" />
              :&nbsp;
            </Text>
          )}
          {hasNwsLink && (
            <NwsButton
              variant="text"
              onClick={() => openLayer(handleAEMLayerLink(nwsLink)!, nwsLabel)}
              size="small"
              data-testid="nwsButton"
            >
              {nwsLabel && <span data-testid="nws-label">{nwsLabel}</span>}
              {nwsSubLabel && <span data-testid="nws-sub-label">&nbsp;{nwsSubLabel}</span>}
            </NwsButton>
          )}
          {!hasNwsLink && (
            <Text variant="copy2" as="span" data-testid="nwsText">
              {nwsLabel && <span data-testid="nws-label">{nwsLabel}</span>}
              {nwsSubLabel && <span data-testid="nws-sub-label">&nbsp;{nwsSubLabel}</span>}
            </Text>
          )}
        </>
      );
    }

    // BEV Agency [CSR-1012]
    if (isBevAgencyVehicle(vehicle)) {
      return (
        <Text variant="copy2" as="span">
          {bevAgencyHeadline && (
            <span data-testid="bev-agency-headline">
              {bevAgencyHeadline}
              <FootnoteReference refId={`fn_${vehicle?.businessModel?.code}`} />
              :&nbsp;
            </span>
          )}

          {bevAgencyLabel && <span data-testid="bev-agency-label">{bevAgencyLabel}</span>}
          {bevAgencySubLabel && (
            <span data-testid="bev-agency-sublabel">&nbsp;{bevAgencySubLabel}</span>
          )}
          {bevAgencyDealerName && (
            <span data-testid="bev-agency-dealer-name">&nbsp;{bevAgencyDealerName}</span>
          )}
        </Text>
      );
    }

    if (vehicle.employeeVehicle) {
      return (
        <Text variant="copy2" data-testid="employeeLabel">
          {employeeLabel}
        </Text>
      );
    }
    return (
      <>
        <Text variant="copy2" data-testid="dealerLabel">
          {!contentConfiguration?.tile.hideDealerZipCode ? `${vehicle.dealer.zipCode} ` : ''}
          {vehicle.dealer.city}
          {vehicle.distance && (
            <Text variant="copy2" as="span" data-testid="dealerDistance">
              &nbsp;({vehicle.distance} {unitTextLabel})
            </Text>
          )}
        </Text>
        <Text variant="copy2" data-testid="dealer-headline">
          {dealerHeadline.trim() !== '' && (
            <>
              {dealerHeadline}
              {vehicle?.businessModel ? (
                <FootnoteReference refId={`fn_${vehicle?.businessModel?.code}`} />
              ) : (
                <FootnoteReference refId="fn_dealer_offer_stockcar" />
              )}
              :&nbsp;
            </>
          )}
          {dealerLabel && <span data-testid="dealer-label">{dealerLabel}</span>}
          {dealerSubLabel && <span data-testid="dealer-sub-label">&nbsp;{dealerSubLabel}</span>}
          {dealerName && <span data-testid="dealer-name">&nbsp;{dealerName}</span>}
        </Text>
      </>
    );
  };

  return (
    <>
      <Layout>
        <LayoutItem align="center" spaceInlineEnd="s">
          <Icon icon={iconPoiSmall} />
        </LayoutItem>
        <LayoutItem align="center" grow="1">
          {getDealerContact()}
        </LayoutItem>
      </Layout>
    </>
  );
};

const LayerContainer = styled.div`
  .nm-layer-title {
    display: none;
  }
`;

const NwsButton = styled((props) => <Button variant="text" size="small" {...props} />)<ButtonProps>`
  text-align: left;
  vertical-align: baseline;
`;

export default DealerLabel;
