import styled, { css } from 'styled-components';
import { ButtonGroup } from '@audi/audi-ui-react-v2';
export const FormWrapper = styled.div `
  display: flex;
  gap: var(${(props) => props.theme.responsive.spacing.l});
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 0px;
  }
`;
export const RadioWrapperToOverwriteLegacyCSS = styled.div `
  display: flex;
  align-items: flex-end;
  gap: 12px;
  height: 30px;
  flex-direction: row;

  input {
    @media screen and (max-width: 479px) {
      height: auto !important;
    }
  }
`;
export const StyledButtonGroup = styled(ButtonGroup) `
  @media screen and (max-width: 600px) {
    width: 100%;

    div {
      width: 100%;
      button {
        width: 100%;
        margin-right: 0px;
      }
    }
  }
`;
export const FormElementWrapper = styled.div `
  margin-block-end: var(${(props) => props.theme.responsive.spacing.l});
  display: flex;
  gap: var(${(props) => props.theme.responsive.spacing.s});
  align-items: center;
`;
export const ReadOnlyFormElementWrapper = styled.div `
  display: flex;
  margin-left: var(${(props) => props.theme.responsive.spacing.l});
  margin-right: var(${(props) => props.theme.responsive.spacing.l});
`;
export const ReadOnlyGroup = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.xl});
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xl});
`;
export const RateWrapper = styled.div `
  display: flex;
  margin: 25px 25px 0px 25px;
  input {
    font-size: x-large;
    font-weight: bold;
    padding: 0px;
  }
  div #Rate__hint-message {
    flex-direction: row-reverse;
    margin: 0px;
  }
`;
export const WebCalcErrWrapper = styled.div `
  display: flex;
  flex-direction: column;
  margin: 0px 25px;
`;
export const WebCalcErrContainer = styled.div `
  display: flex;
  flex-direction: row;
  margin: 0px 25px;
  color: var(${(props) => props.theme.colors.ui.error});

  p {
    color: var(${(props) => props.theme.colors.ui.error});
  }
`;
export const FormColumnWrapper = styled.div `
  flex: 1;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});
`;
export const ReadOnlyColumn = styled.div `
  flex: 1;
  background-color: var(${(props) => props.theme.colors.base.brand.white});
  height: fit-content;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(0 2px 6px rgba(0, 0, 0, 0.2));
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});
`;
export const ProductWrapper = styled.div `
  display: block;
  flex-wrap: wrap;
`;
export const popupContentStyles = css `
  width: 600px;
  max-width: none;
`;
export const HeadingWrapper = styled.div `
  h2 {
    hyphens: auto;
    word-break: break-word;
  }
`;
